<style lang="scss">
.martkplatz-wohnen {
  .head-row-text-normal {
    .text-normal {
      display: inline-block;
      text-transform: none !important;
    }
  }

  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }
  .custom-link {
    color: $blue-dark !important;
    font-weight: 500;
  }
  .custom-link-v2 {
    font-weight: 500;
    background: $blue-dark;
    color: #fff !important;
    padding: 10px 15px;
    display: inline-block;
    &:hover {
      opacity: 0.7;
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
  }
  .bild-box {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .leistung {
    strong {
      display: block;
      font-size: 140%;
      text-transform: uppercase;
    }
    &:before {
      content: '';
      display: block;
      margin: 20px 0;
      width: 150px;
      height: 5px;
      background: $blue-darker;
    }
    &:after {
      content: '';
      display: block;
      margin: 20px 0;
      width: 100px;
      height: 5px;
      background: $blue-darker;
    }
  }
  .text-center-small {
    @media (max-width: 992px) {
      text-align: center !important;
    }
  }
  .content-link-mehrinfo {
    color: #fff !important;
  }
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
      .head-row {
        font-size: 80%;
      }
    }
  }
  .alignItems {
    [class*='col-'] {
      justify-content: center;
      align-self: center;
    }
  }
  .router-link-exact-active {
    &:after {
      display: none;
    }
  }
}
.collapsible-container {
  a {
    color: $blue !important;
  }
  img {
    margin: 15px auto;
    max-width: 100%;
    display: block;
  }
  .info-box {
    padding: 10px;
    background: #eee;
    margin: 20px 0;
  }
  .tipp-box {
    margin-bottom: 20px;
    padding: 10px;
    background: $blue-darker;
    color: #fff;
    strong {
      text-transform: uppercase;
    }
    a {
      color: #fff;
      font-weight: 500;
    }
  }
}
</style>

<template>
  <div class="martkplatz-wohnen content container">
    <h1>Wohnen</h1>
    <p class="intro-txt">Redaktionelle Themenberichte, spannende Wohnportraits und die besten Immobilienangebote im Süden Österreichs geben unseren Lesern einen Überblick über den heimischen Immobilienmarkt. Platzieren Sie Ihre Anzeige in diesem attraktiven Umfeld. Der Wohnen-Teil der Kleinen Zeitung erscheint jeden Freitag (Hauptmarkt) und Mittwoch (Zweitmarkt).</p>
    <div class="intro-img">
      <img src="@/assets/img/img-content/intro-img/wohnen.jpg" alt="Wohnen" class="img-fluid" />
    </div>

    <h2>Individuelle Werbelösungen</h2>
    <p>Nutzen Sie unser umfangreiches Portfolio, um Ihr Werbeziel optimal zu erreichen.</p>

    <TabGroup>
      <Tab tabid="wohnen-tab1" :title="'Raum- und Zeilenanzeigen'" active>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <h3>Produktdaten</h3>
            <p>Nutzen Sie den Marktplatz „Wohnen“ der Kleinen Zeitung. Dieser erscheint jeden Freitag (Hauptmarkt) und Mittwoch (Zweitmarkt) in der Kleinen Zeitung.</p>
            <p><span class="hinweis-headline">NEU</span><br />Der Wohnen-Teil der Kleinen Zeitung erscheint 10x im Jahr (Februar bis November) am letzten Freitag im Monat als umfangreichere Beilage (Supplement).</p>
            <br />
            <p>
              <strong>Anzeigenschluss / Druckunterlagenschluss (Hauptblatt): </strong><br />
              Donnerstag, 10 Uhr / Donnerstag, 11 Uhr für Freitag<br />
              Dienstag, 10 Uhr / Dienstag, 11 Uhr für Mittwoch
            </p>
            <p>
              <strong>Anzeigenschluss / Druckunterlagenschluss (Beilage Kleinanzeigenteil):</strong><br />
              Mittwoch, 18 Uhr
            </p>
            <p>
              <strong>Anzeigenschluss / Druckunterlagenschluss (Beilage redaktioneller Teil): </strong><br />
              Freitags vor Erscheinung. 11 Uhr / Mittwoch 18 Uhr
            </p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content//marktplaetze/prod-wohnen-v2.png" alt="Beruf und Bildung" class="img-fluid" />
          </div>
        </div>

        <!-- -->
        <div class="tabelle-box tabelle-width-50">
          <div class="headline">
            <strong>Millimeterpreis 4C</strong>
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">20,68</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">14,83</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">9,07</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">21,88</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">15,48</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">9,47</div>
          </div>
        </div>
        <!-- -->

        <h3>Raumanzeigen</h3>
        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>1/8 Seite</strong>
                Fixformat: 47 x 135 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">MO - FR</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">2.791,80</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">2.002,05</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">1.224,45</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">SA - SO</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">2.953,80</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">2.089,80</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">1.278,45</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
          </div>
        </div>

        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>1/4 Seite</strong>
                Fixformat: 98 x 135 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">MO - FR</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">5.583,60</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">4.004,10</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">2.448,90</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">SA - SO</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">5.907,60</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">4.179,60</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">2.556,90</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
          </div>
        </div>

        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>1/2 Seite</strong>
                Fixformat: 200 x 135 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">MO - FR</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">11.167,20</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">8.008,20</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">4.897,80</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">SA - SO</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">11.815,20</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">8.359,20</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">5.113,80</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
          </div>
        </div>

        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>1/1 Seite</strong>
                Fixformat: 200 x 275 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">MO - FR</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">22.748,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">16.313,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">9.977,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">SA - SO</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">24.068,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">17.028,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">10.417,00</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
          </div>
        </div>

        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>Rubrikenvorspann</strong>
                Format: 200 x 110 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">MI</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">1.990,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">1.500,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">900,00</div>
              </div>
              <div class="info-txt-tabelle">Erscheinung nur mittwochs möglich.<br />Pro Mittwochausgabe nur ein Rubrikenvorspann verfügbar.</div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x110.svg" alt="200x110" class="img-fluid " />
          </div>
        </div>

        <h3>Zeilenanzeigen</h3>
        <p>Ihre Möglichkeit, eine Zeilenanzeige im seriösen und reichweitenstarken Immobilienmarkt im Süden Österreichs zu platzieren</p>
        <div class="row">
          <div class="col-lg-8 justify-content-center align-self-center">
            <div class="tabelle-box alignItems">
              <div class="headline">
                <strong>Zeilenanzeigen </strong>
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Premium-Anzeigen</span> (inkludiert Farbbild, 5 Zeilen Text, zweizeiliges Logo, Energiezeile und Preiszeile)</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">180,83</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">130,44</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">96,99</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Basis-Anzeigen</span> (inkludiert 4 Zeilen Text, Energie- und Preiszeile)</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">70,82</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">45,62</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">33,58</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Jede weitere Zeile</span></div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">11,82</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">7,56</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">5,64</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Logo 4c</span> je Zeile</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">16,48</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">13,01</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">9,54</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Farbbild</span></div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">65,23</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">51,24</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">38,69</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Icon</span> je Icon (max. 3)</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">3,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">3,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">3,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Willhaben-Code</span></div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">5,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">5,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">5,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Energiezeile</span></div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">7,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">5,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">3,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Wiederholung</span> (Zweitmarkt-Schaltmöglichkeit am Mittwoch)</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">-50%</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">-50%</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">-50%</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 justify-content-center align-self-center">
            <img src="@/assets/img/img-content//marktplaetze/bsp-wohnen.png" alt="Bsp. Wohnen" class="img-fluid" />
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 5 % Werbeabgabe und 20 % Umsatzsteuer.</div>
      </Tab>
      <Tab tabid="wohnen-tab2" :title="'Immo-Map'">
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <p>Die interaktive Immobilienkarte der Kleinen Zeitung ist ausgerichtet für Interessierte und latent Suchende. Mit der prominenten Platzierung im redaktionellen Umfeld (<a href="https://www.kleinezeitung.at/zuhause" target="_blank">www.kleinezeitung.at/zuhause</a>) erreichen Sie Ihre zukünftigen Immobilien-Besitzer mit bis zu durchschn. 10.000 Zugriffen pro Monat.</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content//marktplaetze/immo-map-klz.jpg" alt="Immomap" class="img-fluid" />
            </div>
          </div>
        </div>
        <br /><br />
        <h3>Leistungen &amp; Tarife</h3>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center">
            <h4>Standard Präsentation</h4>
            <ul class="content-ul">
              <li>Bildkarussell von Objekten</li>
              <li>Objektinformationen</li>
              <li>Kontaktdaten</li>
              <li>Logo Abbildung</li>
              <li>Verlinkung auf Website</li>
            </ul>
            <br />
            <h4>Immo Map Add on's</h4>
            <ul class="content-ul">
              <li>Display Werbung <router-link v-slot="{ navigate }" @click="navigateHook(navigate)" :to="{ name: 'Wohnen', hash: '#wohnen-tab3' }" class="custom-link">(Immo Karussell)</router-link></li>
              <li>Content Marketing <router-link v-slot="{ navigate }" @click="navigateHook(navigate)" :to="{ name: 'Wohnen', hash: '#wohnen-tab4' }" class="custom-link">(Sponsored Story oder Advertorial)</router-link></li>
              <li>Print Werbung mit KLEINE EXTRA</li>
            </ul>
            <div class="info-txt">Wichtig: Die Immo Map kann nur als Paket mit einem Add on Ihrer Wahl gebucht werden.</div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content//marktplaetze/immomap-eintrag-v2.jpg" alt="Immomap Eintrag" class="img-fluid shadow" />
            </div>
            <br />
            <div class="tabelle-box alignItems">
              <div class="row head-row">
                <div class="col-lg-6 col-6 text-align-left"></div>
                <div class="col-lg-6 col-6 text-align-right">Monatspreis</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-6 text-align-left">bis 10 Immobilien</div>
                <div class="col-lg-6 col-6 text-align-right">118,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-6 text-align-left">bis 20 Immobilien</div>
                <div class="col-lg-6 col-6 text-align-right">203,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-6 text-align-left">bis 50 Immobilien</div>
                <div class="col-lg-6 col-6 text-align-right">278,00</div>
              </div>
            </div>
          </div>
        </div>

        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
      </Tab>
      <Tab tabid="wohnen-tab3" :title="'Immo-Karussell'">
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <p>Verstärken Sie Ihre Werbebotschaft mit Display-Werbung auf <a href="https://www.kleinezeitung.at" target="_blank">www.kleinezeitung.at</a> und erreichen Sie latent Suchende aus Ihrer Region durch Zielgruppen-Targeting. Ein Werbemittel – mehrere Immobilien!</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content//marktplaetze/wohnen-immokarussell.jpg" alt="Immokrausell" class="img-fluid" />
            </div>
          </div>
        </div>
        <h3>Leistungen &amp; Tarife</h3>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center">
            <ul class="content-ul">
              <li>Mehrere Immobilien in einem Werbemittel</li>
              <li>Automatisierte Werbemittel möglich</li>
              <li>Regionales Zielgruppen-Targeting</li>
            </ul>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="tabelle-box alignItems">
              <div class="row head-row hide-small head-row-text-normal">
                <div class="col-lg-4 col-6 text-align-left"></div>
                <div class="col-lg-4 col-6 text-align-right">Monatspreis</div>
                <div class="col-lg-4 col-6 text-align-right"><span class="text-normal">Als</span>/Monat</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small">bis 10 Immobilien</div>
                <div class="col-6 text-align-left visible-small">Monatspreis</div>
                <div class="col-lg-4 col-6 text-align-right">129,00</div>
                <div class="col-6 text-align-left visible-small">Als/Monat</div>
                <div class="col-lg-4 col-6 text-align-right">11.700</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small">bis 20 Immobilien</div>
                <div class="col-6 text-align-left visible-small">Monatspreis</div>
                <div class="col-lg-4 col-6 text-align-right">179,00</div>
                <div class="col-6 text-align-left visible-small">Als/Monat</div>
                <div class="col-lg-4 col-6 text-align-right">18.200</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small">bis 50 Immobilien</div>
                <div class="col-6 text-align-left visible-small">Monatspreis</div>
                <div class="col-lg-4 col-6 text-align-right">249,00</div>
                <div class="col-6 text-align-left visible-small">Als/Monat</div>
                <div class="col-lg-4 col-6 text-align-right">31.500</div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
      </Tab>
      <Tab tabid="wohnen-tab4" :title="'Content-Marketing'">
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <p>Erreichen Sie Ihre Zielgruppe durch einen digitalen PR-Text. Mit maßgeschneiderten Inhalten und zielgruppenrelevanter Sprache bietet die Kleine Zeitung hier die richtigen Werbelösungen.</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content//prprodukte/advertorial.png" alt="Immokrausell" class="img-fluid" />
            </div>
          </div>
        </div>
        <h3>Leistungen &amp; Tarife</h3>
        <div class="row justify-content-center align-self-center">
          <div class="col-lg-12 justify-content-center align-self-center">
            <h3>Sponsored Story</h3>
            <p>Mit der <strong>Sponsored Story</strong> kreieren unsere Native Experten gemeinsam mit Ihnen lesenswerte, digitale Inhalte speziell für Ihre Zielgruppe. Ihre Botschaften überzeugen im redaktionellen Look &amp; Feel. Sie bleiben in positiver Erinnerung.</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="leistung">
              <strong>Paketinhalt</strong>
              <ul class="content-ul">
                <li>Ausführliches Briefing</li>
                <li>Projektmanagement</li>
                <li>Content-Produktion</li>
                <li>Set-Up-Kosten</li>
                <li>Bewerbung</li>
                <li>umfassendes Reporting</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="leistung">
              <strong>Leistung</strong>
              <ul class="content-ul">
                <li>5.000 garantierte Leser</li>
                <li>Platzierung im Kleine Zeitung Wohnen-Umfeld</li>
                <li>Bewerbung der Sponsored Storys auf allen Plattformen von kleinezeitung.at</li>
                <li>und in den sozialen Netzwerken</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="preis-box">
              <span>Tarif: 4.985,00</span>
            </div>
          </div>
        </div>

        <div class="row justify-content-center align-self-center">
          <div class="col-lg-12 justify-content-center align-self-center">
            <h3>Advertorial</h3>
            <p>Erreichen Sie Ihre Zielgruppe mit einem <strong>Advertorial</strong>: Dieser digitale PR-Text wird für Sie maßgeschneidert. Ihr Unternehmen und Ihre Immobilien stehen klar im Vordergrund.</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="leistung">
              <strong>Paketinhalt</strong>
              <ul class="content-ul">
                <li>Content-Produktion inkl. Bildersuche und Korrektorat</li>
                <li>Bewerbung des Advertorials mittels Karussell Ad (als Branded Content gekennzeichnet mit Verlinkung zum Unternehmen) über die Kleine Zeitung Facebook und Instagram Seite</li>
                <li>1.000 garantierte Zugriffe auf das Advertorial</li>
              </ul>
              <small>Verrechnung über Auktionsguthaben nicht möglich.</small>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content//marktplaetze/geschichte-adkarussell.png" alt="Immokrausell" class="img-fluid img-center" />
          </div>
          <div class="col-lg-12">
            <div class="preis-box">
              <span>Tarif: 997,00</span>
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
      </Tab>
      <Tab tabid="wohnen-tab6" :title="'willhaben.at'">
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <p>Profitieren Sie von der optimalen Kombination „Kleine Zeitung“ und „willhaben“. Mit der reichweitenstärksten Tageszeitung im Süden Österreichs und dem größten digitalen Marktplatz des Landes nutzen Sie das erfolgreichste Kommunikationsnetzwerk Österreichs.</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content//marktplaetze/klz-willhaben.jpg" alt="Kleine Zeitung Willhaben" class="img-fluid" />
            </div>
          </div>
        </div>
        <h3>Ihre Werbemöglichkeiten als Add-On zur Kleinen Zeitung.</h3>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center">
            <ul class="content-ul">
              <li>Billboard 970 x 250 px</li>
              <li>run over vertical</li>
              <li>Anzeigendetailansicht</li>
              <li>Laufzeit 4 Wochen</li>
            </ul>
            <div class="tabelle-box alignItems">
              <div class="row head-row head-row-text-normal">
                <div class="col-lg-4 col-6 text-align-left hide-small"></div>
                <div class="col-lg-4 col-6 text-align-right text-left-small"><span class="text-normal">Als</span></div>
                <div class="col-lg-4 col-6 text-align-right">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small ">Small</div>
                <div class="col-lg-4 col-6 text-align-right text-left-small">10.000</div>
                <div class="col-lg-4 col-6 text-align-right">270,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small ">Medium</div>
                <div class="col-lg-4 col-6 text-align-right text-left-small">20.000</div>
                <div class="col-lg-4 col-6 text-align-right">500,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small ">Large</div>
                <div class="col-lg-4 col-6 text-align-right text-left-small">30.000</div>
                <div class="col-lg-4 col-6 text-align-right">660,00</div>
              </div>
              <div class="info-txt-tabelle">Anforderungen ans Werbemittel: max. 150kb. Gängige Bildformate (jpg, png, gif etc.) oder HTML Werbemittel sind möglich (hier sind clicktags nach iab Standard einzubauen)</div>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content//marktplaetze/wohnen-laptop-willhaben.jpg" alt="Wohnen Laptop Willhaben" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer. Keine Rabatte möglich.</div>
      </Tab>
      <Tab tabid="wohnen-tab7" :title="'KLEINE EXTRA'">
        <div class="row">
          <div class="col-lg-6 order-lg-2 justify-content-center align-self-center">
            <div class="responsive-video">
              <video autostart="false" controls="">
                <source src="https://assets.smd-digital.at/tarif-klz/videos/klzar_web_finale.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Was ist KLEINE EXTRA?</h3>
            <p>
              Mit <strong>KLEINE EXTRA</strong> erleben unsere ZeitungsleserInnen eine neue Dimension des Zeitungslesens. Die Kleine Zeitung wird Augmented Reality (kurz AR) – diese Technologie steckt hinter der KLEINE EXTRA-Funktion - ab sofort als Testlauf bei diversen redaktionellen Inhalten, Ihren Inseraten sowie an jedem letzten Freitag des Monats, für den Themenschwerpunkt Wohnen &amp;
              Mobilität EXTRA einsetzen und den LeserInnen damit wirklichkeitserweiternde Aha-Erlebnisse bescheren und das, indem Sie EXTRA-Inhalte wie 3D-Modelle, Videos und vieles mehr direkt auf der Zeitungsseite und auf Ihren Inseraten entdecken können. Diese sind mit einem eigenen Symbol gekennzeichnet und warten darauf gescannt zu werden.
            </p>
            <router-link :to="{ name: 'KleineExtra' }" class="custom-link-v2">Mehr Informationen finden Sie hier</router-link>
          </div>
        </div>
      </Tab>
    </TabGroup>
    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/marktplaetze/Wohnen-07-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif Wohnen
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>1.486 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Wohnen',
  components: {
    //Modal,
    TabGroup,
    Tab,
  },
  setup() {
    const navigateHook = (navigate: any) => {
      navigate();
    };
    return {
      navigateHook,
    };
  },
});
</script>
