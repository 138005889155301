
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Wohnen',
  components: {
    //Modal,
    TabGroup,
    Tab,
  },
  setup() {
    const navigateHook = (navigate: any) => {
      navigate();
    };
    return {
      navigateHook,
    };
  },
});
